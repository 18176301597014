import React, { useEffect, useRef } from 'react'
import { SVGFonts } from './Fonts'

export default function Drawer({ config, local = {}, className, setRef }) {
  const ref = useRef()
  useEffect(() => { if (ref.current) setRef(ref) }, [ref])
  const { viewBox, mask, hide } = config
  const { background, images, texts } = local
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        className={`h-full w-max lg:w-auto max-w-full ${className}`}
        style={{ filter: 'drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1))' }}>
        {mask}
        <SVGFonts names={texts?.map(t => t.font)} />
        <g mask={mask && 'url(#mask)'}>
          <rect height="100%" width="100%" fill={background?.color || '#ffffff'} />
          {background?.image &&
            <image height="100%" width="100%" preserveAspectRatio="xMidYMid slice" href={background.imageR || background.image} />
          }
        </g>
        {images?.map(({ x, y, width, height, href, opacity }) => (
          <image key={`${x}-${y}`} x={x} y={y} height={height} width={width} href={href} opacity={opacity || 1} />
        ))}
        {texts?.map(({ x, y, font, size, color, content, weight, style, textAnchor }) => (
          <text key={`${x}-${y}-${content}`} x={x} y={y} fill={color} fontFamily={font} fontSize={size} fontWeight={weight} fontStyle={style} textAnchor={textAnchor || "middle"}>
            {content.split('\n').filter(value => value !== '').map((value, i) => <tspan x={x} dy={i === 0 ? '0em' : '1.1em'} key={value}>{value}</tspan>)}
          </text>
        ))}
        <g id="hide" dangerouslySetInnerHTML={{ __html: hide }} />
      </svg>
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        className="fixed left-full opacity-0 pointer-events-none">
        {mask}
        <SVGFonts names={texts?.map(t => t.font)} />
        <g mask={mask && 'url(#mask)'}>
          <rect height="100%" width="100%" fill={background?.color || '#ffffff'} />
          {background?.image &&
            <image height="100%" width="100%" preserveAspectRatio="xMidYMid slice" href={background.imageR || background.image} />
          }
        </g>
        {images?.map(({ x, y, width, height, href, opacity }) => (
          <image key={`${x}-${y}`} x={x} y={y} height={height} width={width} href={href} opacity={opacity || 1} />
        ))}
        {texts?.map(({ x, y, font, size, color, content, weight, style, textAnchor }) => (
          <text key={`${x}-${y}-${content}`} x={x} y={y} fill={color} fontFamily={font} fontSize={size} fontWeight={weight} fontStyle={style} textAnchor={textAnchor || "middle"}>
            {content.split('\n').filter(value => value !== '').map((value, i) => <tspan x={x} dy={i === 0 ? '0em' : '1em'} key={value}>{value}</tspan>)}
          </text>
        ))}
      </svg>
    </>
  )
}
