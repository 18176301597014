import React, { useEffect, useState } from 'react'

const loaded = {}

function loadFont(name, weigth, style) {

  if (loaded[name]) return Promise.resolve(loaded[name])
  return fetch(`https://fonts.googleapis.com/css?family=${name.replace(/\s/g, '+')}:ital,wght@0,400;0,700;1,400;1,700&display=swap`)
  .then(res => res.text())
  .then(css => {
    // TODO: allow regex to remove the last "/"
    const latinParts = [...(css.match(/\/\* latin \*\/[^\*]*/g) || [])].map(r => r.slice(0, -1))
    return Promise.all(latinParts.map(latin => {
      const woffUrl = latin.match(/https:\/\/[^)]+/g)[0]
      return fetch(woffUrl)
      .then(res => res.blob())
      .then(blob =>
        new Promise(resolve => {
          const reader = new FileReader()
          reader.addEventListener('load', function() {
            resolve(latin.replace(woffUrl, this.result))
          })
          reader.readAsDataURL(blob)
        }))
    }))
    .then(res => {
      loaded[name] = res.join('\n')
      return res.join('\n')
    })
  })
}

export function SVGFonts({ names }) {
  const [fonts, setFonts] = useState([])
  useEffect(() => {
    if (!names) return
    Promise.all(names.map(n => loadFont(n))).then(fonts => setFonts(fonts))
  }, [names])
  return (
    <defs
      dangerouslySetInnerHTML={{
        __html: `<style type="text/css">${fonts.join('\n')}</style>`
      }} />
  )
}
